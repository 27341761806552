<template>
  <div class="fullScreen" :class="{ show: showEditDialog }" v-loading="loading">
    <Crumbs>
      <el-breadcrumb-item slot="after"> {{ getTitle }} </el-breadcrumb-item>
    </Crumbs>
    <div class="fullScreenMain" id="Scrollbar">
      <div class="fullScreenMainHeader"></div>

      <div class="fullScreenMainCon">
        <el-form ref="form" :model="form" :rules="rules" style="margin-bottom: 20px">
          <el-descriptions direction="vertical" :column="6" border style="width: 100%">
            <el-descriptions-item
              labelClassName="registrationLabelClassName"
              contentClassName="registrationContentClassName"
              label="单据编号"
              v-if="options.list_s != 0"
            >
              <el-form-item prop="expenseNumber" ref="expenseNumber">
                {{ form.expenseNumber }}
              </el-form-item>
            </el-descriptions-item>

            <el-descriptions-item
              labelClassName="registrationLabelClassName"
              contentClassName="registrationContentClassName"
              label="填报人"
            >
              <el-form-item prop="createName" ref="createName">
                {{ form.createName }}
              </el-form-item>
            </el-descriptions-item>
            <el-descriptions-item
              labelClassName="registrationLabelClassName"
              contentClassName="registrationContentClassName"
              label="申请日期"
            >
              <el-form-item prop="taskDate" ref="taskDate">
                {{ form.taskDate | dateFormat }}
              </el-form-item>
            </el-descriptions-item>
            <el-descriptions-item
              labelClassName="registrationLabelClassName"
              contentClassName="registrationContentClassName"
              label="申请人"
            >
              <template slot="label"> <span style="color: red">* </span> 申请人 </template>
              <el-form-item prop="applyName" ref="applyName">
                <SelectDialog v-if="disabled" @selectDataChange="selectDataChange">
                  <el-input
                    slot="button"
                    :readonly="true"
                    style="cursor: pointer"
                    v-model="form.applyName"
                    placeholder="请选择申请人"
                  ></el-input>
                </SelectDialog>
                <span v-else>{{ form.applyName }}</span>
              </el-form-item>
            </el-descriptions-item>
            <el-descriptions-item
              labelClassName="registrationLabelClassName"
              contentClassName="registrationContentClassName"
              label="发生日期"
            >
              <template slot="label"> <span style="color: red">* </span> 发生日期 </template>
              <el-form-item prop="occurDate" ref="occurDate">
                <el-date-picker
                  v-if="disabled"
                  :clearable="false"
                  v-model="form.occurDate"
                  type="date"
                  value-format="timestamp"
                  placeholder="选择日期时间"
                >
                </el-date-picker>
                <span v-else> {{ form.occurDate | dateFormat }}</span>
              </el-form-item>
            </el-descriptions-item>

            <el-descriptions-item
              label-class-name="businessOutSource registrationLabelClassName"
              content-class-name="businessOutSource registrationContentClassName"
              :span="options.list_s == 0 ? 2 : 1"
            >
              <template slot="label">
                <span style="color: #000">登记类型</span>
              </template>
              <el-form-item prop="registrationType" ref="registrationType">
                <!-- <Dictionary
                    v-if="disabled"
                    :clearable="false"
                    v-model="form.registrationType"
                    code="REGISTRATION_TYPE"
                    placeholder="请选择登记类型"
                  /> -->
                <!-- <span v-else> -->
                {{ form.registrationType | dict(registrationTypeList) }}
                <!-- </span> -->
              </el-form-item>
            </el-descriptions-item>
            <el-descriptions-item
              labelClassName="registrationLabelClassName"
              contentClassName="registrationContentClassName"
              label="招待等级"
              :span="4"
            >
              <template slot="label"> <span style="color: red">* </span> 招待等级 </template>
              <!-- <el-form-item prop="hospitalityGrade" ref="hospitalityGrade">
                <Dictionary
                  v-if="disabled"
                  :clearable="false"
                  v-model="form.hospitalityGrade"
                  code="HOSPITALITY_GRADE"
                  placeholder="请选择招待等级" />
          
              </el-form-item> -->

              <el-form-item
                labelClassName="registrationLabelClassName"
                contentClassName="registrationContentClassName"
                prop="hospitalityGrade"
              >
                <el-radio-group v-model="form.hospitalityGrade">
                  <el-radio
                    :disabled="!disabled"
                    :label="item.dictVal"
                    v-for="item in hospitalityGradeList"
                    :key="item.id"
                    ><span
                      :style="
                        !disabled
                          ? form.hospitalityGrade == item.dictVal
                            ? 'color:#409EFF'
                            : ''
                          : ''
                      "
                      >{{ item.dictName }}</span
                    ></el-radio
                  >
                </el-radio-group>
              </el-form-item>
            </el-descriptions-item>
            <el-descriptions-item
              labelClassName="registrationLabelClassName"
              contentClassName="registrationContentClassName"
              label="招待人数"
              span="2"
            >
              <template slot="label"> <span style="color: red">* </span> 招待人数 </template>
              <el-form-item prop="numberPeople">
                <el-input
                  v-if="disabled"
                  onkeyup="value=value.replace(/^\D*(\d*(?:\d{0,2})?).*$/g, '$1')"
                  style="width: 100%"
                  v-model.number="form.numberPeople"
                  placeholder="请输入招待人数"
                >
                </el-input>
                <span v-else>{{ form.numberPeople + '人' }}</span>
              </el-form-item>
            </el-descriptions-item>

            <template v-if="options.list_s != 0">
              <el-descriptions-item
                labelClassName="registrationLabelClassName"
                contentClassName="registrationContentClassName"
                label="申请单位"
              >
                <template slot="label"> <span style="color: red">* </span> 申请单位 </template>
                <el-form-item prop="companyType" ref="companyType">
                  <Dictionary
                    v-if="options.passText == '发放'"
                    :clearable="false"
                    v-model="form.companyType"
                    code="COMPANY_TYPE"
                    placeholder="请选择申请单位"
                  />
                  <span v-else>
                    {{ form.companyType | dict(companyTypeList) }}
                  </span>
                </el-form-item>
              </el-descriptions-item>
              <el-descriptions-item
                labelClassName="registrationLabelClassName"
                contentClassName="registrationContentClassName"
                label="费用类型"
                :span="!form.expenseType ? 5 : 1"
              >
                <template slot="label"> <span style="color: red">* </span> 费用类型 </template>
                <el-form-item prop="expenseType" ref="expenseType">
                  <Dictionary
                    v-if="options.passText == '发放'"
                    :clearable="false"
                    v-model="form.expenseType"
                    code="EXPENSE_TYPE"
                    @change="expenseTypeChange"
                    placeholder="请选择费用类型"
                  />
                  <span v-else>
                    {{ form.expenseType | dict(expenseTypeList) }}
                  </span>
                </el-form-item>
              </el-descriptions-item>
              <el-descriptions-item
                labelClassName="registrationLabelClassName"
                contentClassName="registrationContentClassName"
                label="费用所属项目"
                :span="4"
                v-if="form.expenseType == 'PROJECT'"
              >
                <template slot="label"> <span style="color: red">* </span> 费用所属项目 </template>
                <el-form-item prop="expenseBelongId" ref="expenseBelongId">
                  <el-select
                    style="width: 100%"
                    v-model="form.expenseBelongId"
                    v-if="options.passText == '发放'"
                    clearable
                    filterable
                    placeholder="请选择项目"
                  >
                    <el-option
                      v-for="(item, index) in projectNameList"
                      :key="index"
                      :label="item.projectName"
                      :value="item.id"
                    ></el-option>
                  </el-select>
                  <span v-else>
                    {{ form.expenseBelongName }}
                  </span>
                </el-form-item>
              </el-descriptions-item>
              <el-descriptions-item :span="2" v-if="form.expenseType == 'BUSINESS'">
                <template slot="label"
                  >支出类型
                  <el-button
                    style="margin-left: 10px"
                    size="mini"
                    type="warning"
                    @click="disbursementTypeIsShow = true"
                    >类型说明</el-button
                  ></template
                >
                <el-form-item prop="disbursementType" ref="disbursementType">
                  <!-- :disabled="[2, 3, 4].includes(options.list_s)" -->
                  <el-radio-group
                    v-if="options.passText == '发放'"
                    v-model="form.disbursementType"
                    @change="onDisbursementTypeChange"
                  >
                    <el-radio label="THROUGH_RATIFICATION_IMPLEMENT">经批准开展</el-radio>
                    <el-radio label="UNDEFINED_RATIFICATION_IMPLEMENT"
                      >经批准开展的面上支出</el-radio
                    >
                    <el-radio label="COORDINATE_IMPLEMENT">配合开展</el-radio>
                  </el-radio-group>
                  <span v-else>
                    {{ form.disbursementType | dict(dictData.disbursementType) }}
                  </span>
                  <div class="color_r" v-if="!form.disbursementType && options.passText == '发放'">
                    请选择商务支出类型
                  </div>
                </el-form-item>
              </el-descriptions-item>
              <el-descriptions-item
                labelClassName="registrationLabelClassName"
                contentClassName="registrationContentClassName"
                :span="4"
                contentStyle="min-width:400px;"
                v-if="form.expenseType == 'BUSINESS'"
              >
                <template slot="label">
                  <span style="color: red">* </span>
                  <span v-if="form.disbursementType == 'UNDEFINED_RATIFICATION_IMPLEMENT'"
                    >商务说明</span
                  >
                  <span v-else>费用所属商务</span>
                </template>
                <el-form-item ref="expenseBelongId">
                  <template v-if="form.disbursementType == 'UNDEFINED_RATIFICATION_IMPLEMENT'">
                    <el-input
                      v-if="options.passText == '发放'"
                      type="textarea"
                      style="width: 100%"
                      @input="$forceUpdate()"
                      v-model="form.businessContent"
                      placeholder="请输入商务面上支出说明"
                      :autosize="{ minRows: 2, maxRows: 4 }"
                      ref="businessContentRef"
                    >
                    </el-input>
                    <span v-else style="display: inline-block; line-height: 40px; height: 40px">{{
                      form.businessContent
                    }}</span>
                  </template>
                  <template v-else>
                    <el-select
                      style="width: 100%"
                      v-model="form.expenseBelongId"
                      v-if="options.passText == '发放'"
                      @input="$forceUpdate()"
                      clearable
                      filterable
                      placeholder="请选择商务"
                    >
                      <el-option
                        v-for="item in businessNameList"
                        :key="item.id"
                        :label="item.businessName"
                        :value="item.id"
                      ></el-option>
                    </el-select>
                    <span v-else>
                      {{ form.expenseBelongName }}
                    </span>
                  </template>
                </el-form-item>
                <!-- 必填校验 -->
                <div
                  style="font-size: 12px; padding-left: 10px; margin-top: 5px"
                  class="color_r"
                  v-if="
                    !form.expenseBelongId &&
                    form.disbursementType !== 'UNDEFINED_RATIFICATION_IMPLEMENT' &&
                    options.passText == '发放'
                  "
                >
                  请选择费用所属
                </div>
                <div
                  style="font-size: 12px; padding-left: 10px; margin-top: 5px"
                  class="color_r"
                  v-if="
                    !form.businessContent &&
                    form.disbursementType == 'UNDEFINED_RATIFICATION_IMPLEMENT' &&
                    options.passText == '发放'
                  "
                >
                  请填写商务说明
                </div>
              </el-descriptions-item>
              <el-descriptions-item
                labelClassName="registrationLabelClassName"
                contentClassName="registrationContentClassName"
                label="费用所属部门"
                :span="4"
                v-if="form.expenseType == 'PUBLIC_SPENDING'"
              >
                <template slot="label"> <span style="color: red">* </span> 费用所属部门 </template>
                <el-form-item prop="expenseBelongId" ref="expenseBelongId">
                  <el-select
                    v-model="form.expenseBelongId"
                    v-if="options.passText == '发放'"
                    placeholder="请选择"
                    style="width: 100%"
                  >
                    <el-option
                      v-for="item in deptList"
                      :key="item.id"
                      :label="item.deptName"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                  <span v-else>
                    {{ form.expenseBelongName }}
                  </span>
                </el-form-item>
              </el-descriptions-item>
              <el-descriptions-item
                labelClassName="registrationLabelClassName"
                contentClassName="registrationContentClassName"
                label="支出说明"
                :span="6"
              >
                <template slot="label"> <span style="color: red">* </span> 支出说明 </template>
                <el-form-item prop="expenditureExplain" ref="expenditureExplain">
                  <el-input
                    v-if="options.passText == '发放'"
                    type="textarea"
                    style="width: 100%"
                    v-model="form.expenditureExplain"
                    placeholder="请输入支出说明,列如：人物、地点、事项。"
                  >
                  </el-input>
                  <el-input
                    v-else
                    type="textarea"
                    :autosize="{ minRows: 2, maxRows: 99 }"
                    style="width: 100%"
                    v-model="form.expenditureExplain"
                    :disabled="!form.isDisplayEditButton"
                    placeholder="请输入支出说明,列如：人物、地点、事项。"
                  >
                  </el-input>
                </el-form-item>
              </el-descriptions-item>
            </template>
          </el-descriptions>
        </el-form>

        <div style="height: 40px; margin: 15px 0px">
          <el-button
            style="position: absolute; right: 2%"
            type="success"
            v-if="
              (form.status == 10 && options.passText != '发放') ||
              (form.status == 100 && options.passText != '发放')
            "
            @click="exportFileZipFn"
            :loading="loading"
          >
            全部附件下载
          </el-button>
        </div>
        <el-table border :header-cell-class-name="headerInvoiceClassName" :data="form.detailList">
          <el-table-column label="序号" type="index" width="50"> </el-table-column>
          <el-table-column
            prop="cost"
            align="center"
            width="150"
            label="金额 (元)"
            :show-overflow-tooltip="false"
          >
            <template slot-scope="scope">
              {{ scope.row.cost | applyAmount }}
            </template>
          </el-table-column>
          <el-table-column
            scoped-slot
            prop="contentDetail"
            :width="options.list_s != 0 && options.list_s != 2 ? '695' : '1370'"
            :label="'事由附件详细说明\n（含参与人员）'"
            :show-overflow-tooltip="false"
            :render-header="addRedStar"
          >
            <template slot-scope="scope">
              <el-input
                v-if="disabled"
                placeholder="请输入"
                type="textarea"
                :autosize="{ minRows: 2, maxRows: 999 }"
                v-model="scope.row.contentDetail"
              ></el-input>
              <span v-else
                ><el-input
                  :disabled="!form.isDisplayEditButton"
                  type="textarea"
                  :autosize="{ minRows: 2, maxRows: 999 }"
                  v-model="scope.row.contentDetail"
                ></el-input
              ></span>
              <div class="color_r" v-if="!scope.row.contentDetail && status == 1">
                事由附件详细说明必填
              </div>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            min-width="340"
            label="发票附件上传"
            v-if="options.list_s != 0 && options.list_s != 2"
            :show-overflow-tooltip="false"
          >
            <template slot="header">
              <div>发票附件上传</div>
              <div>只能上传pdf或图片.png .jpg .jpeg格式</div>
            </template>
            <template slot-scope="scope">
              <div class="upload-center">
                <UploadFileToinvoice
                  v-show="options.passText == '发放'"
                  :type="type"
                  :fields="fields"
                  @click.native="uploadIndex(scope.$index)"
                  @uploadFileToinvoice="invoiceUpload"
                ></UploadFileToinvoice>
              </div>
              <div
                v-for="(n, index) in scope.row.expenseFileList"
                :key="index"
                class="invoice-file"
              >
                <template v-if="n.fileType === 'INVOICE'">
                  <div class="invoice-file-left">
                    <div>
                      <a
                        v-if="isShowType(n)"
                        style="text-decoration: revert; color: #409eff; padding-right: 8px"
                        target="_blank"
                        :href="`${filepath}${n.filePath}`"
                      >
                        {{ n.fileName }}
                      </a>
                      <span v-else class="img_txt" @click="imgIsShow(n, index)">{{
                        n.fileName
                      }}</span>
                    </div>
                    <div>
                      <span style="color: red" v-if="n.fileInvoiceNoType === 'EXIST'"
                        >发票识别存疑</span
                      >
                      <!-- <span style="color: red" v-else>编码：{{ n.invoiceNo }}</span> -->
                    </div>
                  </div>

                  <i
                    @click="del(index, scope.$index, n)"
                    v-show="options.passText == '发放'"
                    class="iconfont iconerror iconCost"
                  ></i
                ></template>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            align="left"
            width="330"
            label="非发票附件上传"
            v-if="options.list_s != 0 && options.list_s != 2"
            :show-overflow-tooltip="false"
          >
            <template slot-scope="scope">
              <div class="upload-center">
                <Upload
                  v-show="options.passText == '发放'"
                  :type="type"
                  :fields="fields"
                  @click.native="uploadIndex(scope.$index)"
                  @upload="upload"
                ></Upload>
              </div>
              <div
                v-for="(n, index) in scope.row.expenseFileList"
                :key="index"
                style="margin-top: 5px"
              >
                <template v-if="n.fileType === 'NORMAL'">
                  <a
                    v-if="isShowType(n)"
                    style="text-decoration: revert; color: #409eff; padding-right: 8px"
                    target="_blank"
                    :href="`${filepath}${n.filePath}`"
                  >
                    {{ n.fileName }}
                  </a>
                  <span v-else class="img_txt" @click="imgIsShow(n, index)">{{ n.fileName }}</span>
                  <i
                    @click="del(index, scope.$index, n)"
                    v-show="options.passText == '发放'"
                    class="iconfont iconerror iconCost"
                  ></i
                ></template>
              </div>
            </template>
          </el-table-column>
        </el-table>

        <el-descriptions title="" :column="2" border>
          <el-descriptions-item label="合计" labelStyle="width:15%">
            {{ money | applyAmount }}
          </el-descriptions-item>
          <el-descriptions-item label="金额大写" labelStyle="width:15%">
            {{ money | numberParseChina }}
          </el-descriptions-item>
        </el-descriptions>
        <OpinionArea
          ref="opinionArea"
          :title="'登记进度'"
          :procInstId="form.processInstId"
          :instInvolved="instInvolved"
        ></OpinionArea>
      </div>

      <div class="fullScreenOperation">
        <el-button
          v-if="disabled && form.status != 10"
          type="success"
          class="teal_bg"
          :loading="loading"
          @click="addBusinessCost(1)"
        >
          提交
        </el-button>
        <el-button
          v-if="disabled && form.status != 10"
          type="success"
          :loading="loading"
          @click="addBusinessCost(0)"
        >
          {{ options.id ? '保存' : '暂存' }}
        </el-button>
        <el-button
          v-if="form.isDisplayEditButton"
          type="warning"
          :loading="loading"
          @click="addBusinessCost(0)"
        >
          编辑
        </el-button>
        <el-button
          v-if="options.list_s == 2 && options.status != 100 && options.status != 5"
          type="success"
          :loading="loading"
          @click="reject"
        >
          撤回
        </el-button>
        <el-button
          v-if="options.passText == '发放'"
          type="success"
          :loading="loading"
          @click="notarize"
        >
          确认
        </el-button>
        <el-button v-if="options.passText == '发放'" type="danger" @click="handleFail"
          >驳回</el-button
        >
        <el-button type="info" @click="re">返回</el-button>
      </div>
    </div>

    <EditDialog :isShow.sync="isDialog">
      <template v-slot:title>
        <span style="color: #409eff">登记驳回</span>
      </template>
      <template v-slot:content>
        <el-form
          class="column3"
          label-width="6em"
          ref="ruleForm"
          :model="examineForm"
          :rules="examineRules"
        >
          <el-form-item v-if="examineForm.status == 1" label="审核意见" style="width: 100%">
            <el-input
              v-model.trim="examineForm.opinion"
              type="textarea"
              placeholder="通过"
            ></el-input>
          </el-form-item>
          <el-form-item v-else label="审核意见" prop="opinion" style="width: 100%">
            <el-input
              v-model.trim="examineForm.opinion"
              type="textarea"
              placeholder="请输入原因"
            ></el-input>
          </el-form-item>
        </el-form>
      </template>
      <template v-slot:footer>
        <el-button type="success" @click="submit">保存</el-button>
        <el-button type="warning" plain @click="isDialog = false">取消</el-button>
      </template>
    </EditDialog>
    <DialogImg
      v-if="dialogVisible"
      :dialogVisible.sync="dialogVisible"
      :filepath="filepath"
      :imgList="imgList"
      :img_index="img_index"
    ></DialogImg>

    <el-dialog
      width="60%"
      title="支出类型说明"
      append-to-body
      :visible.sync="disbursementTypeIsShow"
    >
      <div class="imgDialog">
        <img
          :src="`${disbursementTypeUrl}?${new Date().getTime()}`"
          alt="支出类型说明"
          width="100%"
        />
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  components: {
    Crumbs: () => import('@/components/Crumbs.vue'),
    Dictionary: () => import('@/components/Dictionary.vue'),
    EditDialog: () => import('@/components/EditDialog.vue'),
    OpinionArea: () => import('@/components/OpinionArea.vue'),
    Upload: () => import('@/components/upload/Upload.vue'),
    UploadFileToinvoice: () => import('@/components/upload/uploadFileToinvoice.vue'),
    DialogImg: () => import('@/components/DialogImg.vue'),
    SelectDialog: () => import('@/components/selectDialog.vue'),
  },
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Object,
      default: function () {
        return {}
      },
    },
    getTitle: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      dictData: {
        disbursementType: [], //支出类型
      },
      showEditDialog: false,
      disabled: false,
      loading: false,
      form: {},
      rules: {
        applyName: [{ required: true, message: '请选择申请人', trigger: ['blur', 'change'] }],
        occurDate: [{ required: true, message: '选择发生日期时间', trigger: ['blur', 'change'] }],
        hospitalityGrade: [
          { required: true, message: '请选择招待等级', trigger: ['blur', 'change'] },
        ],
        companyType: [{ required: true, message: '请选择申请单位', trigger: ['blur', 'change'] }],
        registrationType: [
          { required: true, message: '请选择登记类型', trigger: ['blur', 'change'] },
        ],
        expenseType: [{ required: true, message: '请选择费用类型', trigger: ['blur', 'change'] }],

        expenseBelongId: [
          { required: true, message: '请选择费用所属', trigger: ['blur', 'change'] },
        ],
        expenditureExplain: [{ required: true, message: '请输入支出说明内容', trigger: 'blur' }],
        numberPeople: [{ required: true, message: '请输入招待人数', trigger: 'blur' }],
      },
      deptList: [], // 部门列表
      projectNameList: [],
      businessNameList: [],
      //文件上传
      filepath: process.env.VUE_APP_FILEPATH,
      fields: {
        name: 'fileName',
        path: 'filePath',
        type: 'type',
      },
      type: 'CSDJFJ',
      Index: 0,
      status: 0,
      fileDeleteList: [], //删除得附件数组
      detailDeleteList: [], //点击删除得数组
      companyTypeList: [],
      expenseTypeList: [],
      registrationTypeList: [],
      hospitalityGradeList: [],
      instInvolved: {}, //当前进度处理人数组
      isDialog: false,
      // 审批 表单数据
      examineForm: {
        opinion: '',
        id: '',
      },
      // 表单 验证
      examineRules: {
        opinion: [{ required: true, message: '请输入审核意见', trigger: 'blur' }],
      },
      dialogVisible: false,
      imgList: [],
      temporaryList: [],
      img_index: 0,

      disbursementTypeIsShow: false,
      disbursementTypeUrl:
        'https://pro.huitukj.com/iip-api/profile/OPERATION_MANUAL/商务支出类型.png',
    }
  },
  inject: ['re'],
  created() {
    this.getType()
    this.getProjectNameList()
    this.getbusinessNameList()
    this.getDeptList()
    this.$api.dict
      .listSysDictData('BUSINESS_DISBURSEMENT_TYPE', true)
      .then(res => {
        this.dictData.disbursementType = res.data
      })
      .catch(err => {
        console.log(err)
      })
  },
  computed: {
    ...mapState({
      userInfo: state => state.user.userInfo,
    }),
    money() {
      let sum = 0
      if (this.form.hospitalityGrade == 'FIRST_GEAR') {
        sum = this.form.numberPeople * 500
      } else if (this.form.hospitalityGrade == 'SECOND_FIRST_GEAR') {
        sum = this.form.numberPeople * 300
      } else if (this.form.hospitalityGrade == 'SECOND_GEAR') {
        sum = this.form.numberPeople * 200
      } else if (this.form.hospitalityGrade == 'THIRD_GEAR') {
        sum = this.form.numberPeople * 100
      } else if (this.form.hospitalityGrade == 'FOURTH_GEAR') {
        sum = this.form.numberPeople * 50
      }
      if (this.form.detailList) {
        this.form.detailList.forEach(v => (v.cost = sum))
      }
      return sum
    },
  },
  watch: {
    isShow: {
      immediate: true, //初始化立即执行
      handler: function (newVal) {
        this.showEditDialog = newVal
        if (newVal) {
          this.$nextTick(() => {
            let scroll = document.getElementById('Scrollbar')
            if (scroll) {
              scroll.scrollTop = 0
            }
          })
        }
      },
    },
    options: {
      immediate: true, //初始化立即执行
      deep: true, //对象深度监测
      handler: function (newVal, oldVal) {
        this.fileDeleteList = []
        this.detailDeleteList = []
        this.form = {}
        if (newVal) {
          if (![2, 3, 4].includes(newVal.list_s)) {
            this.disabled = true
          } else {
            this.disabled = false
          }
          this.init()
          // this.getUser()
        }
      },
    },
    temporaryList: {
      deep: true,
      immediate: true,
      handler: function (newVal) {
        if (newVal) {
          newVal.forEach(v => {
            if (!this.isShowType(v)) {
              this.imgList.push(v)
            }
          })
        }
      },
    },
  },
  filters: {
    applyAmount(n) {
      if (!n) return
      return Number(n).toLocaleString()
    },
  },
  methods: {
    headerInvoiceClassName({ row, column, rowIndex, columnIndex }) {
      if (column.label === '发票附件上传') {
        return ['headerInvoiceClassName']
      }
      return []
    },
    expenseTypeChange() {
      this.form.expenseBelongId = null
      this.form.disbursementType = null
      if (this.form.expenseType == 'BUSINESS') {
        this.form.disbursementType = 'THROUGH_RATIFICATION_IMPLEMENT'
      }
      this.$refs.form?.clearValidate('expenseBelongId')
    },
    async exportFileZipFn() {
      let execute = false
      execute = this.form.detailList.some(v => v.expenseFileList.some(n => !!n))
      if (execute) {
        this.loading = true
        this.$api.common
          .exportFileZip({ documentId: this.form.id })
          .then(res => {
            const blob = new Blob([res], { type: 'application/zip' })
            const fileName = `厨师支出登记&${this.form.expenseNumber}&${this.form.id}`
            // const fileName = this.form.id
            if ('download' in document.createElement('a')) {
              // 非IE下载
              const elink = document.createElement('a')
              elink.download = fileName
              elink.style.display = 'none'
              elink.href = window.URL.createObjectURL(blob)
              document.body.appendChild(elink)
              elink.click()
              window.URL.revokeObjectURL(elink.href) // 释放URL 对象
              document.body.removeChild(elink)
            } else {
              // IE10+下载
              navigator.msSaveBlob(blob, fileName)
            }
            this.loading = false
            this.$message.success('下载成功')
          })
          .catch(err => {
            this.loading = false
            console.log(err)
          })
      } else {
        this.$message.warning('当前单据没有附件可下载')
      }
    },
    isShowType(n) {
      let arr = n.filePath.split('.')
      let str = arr[arr.length - 1]
      let srtArr = [
        'bmp',
        'dib',
        'pcp',
        'dif',
        'wmf',
        'gif',
        'jpg',
        'tif',
        'eps',
        'psd',
        'cdr',
        'iff',
        'tga',
        'pcd',
        'mpt',
        'png',
        'jpeg',
      ]
      if (srtArr.indexOf(str) == -1) {
        return true
      } else {
        return false
      }
    },
    imgIsShow(n, index) {
      this.img_index = this.imgList.findIndex(
        v =>
          (v.id && n.id && v.id === n.id) ||
          (v.fileName === n.fileName && v.filePath === n.filePath)
      )
      this.dialogVisible = true
    },

    submit() {
      let _obj = {
        id: this.form.id,
        opinion: this.examineForm.opinion,
      }
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.loading = true

          this.$api.register
            .approvalReject(_obj)
            .then(res => {
              this.$message({
                message: '驳回成功',
                type: 'success',
              })

              this.loading = false
              this.isDialog = false
              this.re()
            })
            .catch(err => {
              console.log(err)
              this.loading = false
              this.isDialog = false
            })
        } else {
          console.log('error submit!!')

          return false
        }
      })
    },
    /** 点击 "不通过" 按钮 **/
    handleFail() {
      this.examineForm = {
        opinion: '',
      }
      this.isDialog = true
    },
    getType() {
      this.$api.dict
        .listSysDictData('COMPANY_TYPE', true)
        .then(res => {
          this.companyTypeList = res.data
        })
        .catch(err => {
          console.log(err)
        })
      this.$api.dict
        .listSysDictData('EXPENSE_TYPE', true)
        .then(res => {
          this.expenseTypeList = res.data
        })
        .catch(err => {
          console.log(err)
        })
      this.$api.dict
        .listSysDictData('REGISTRATION_TYPE', true)
        .then(res => {
          this.registrationTypeList = res.data
        })
        .catch(err => {
          console.log(err)
        })
    },
    async init() {
      await this.$api.dict
        .listSysDictData('HOSPITALITY_GRADE', true)
        .then(res => {
          this.hospitalityGradeList = res.data
        })
        .catch(err => {
          console.log(err)
        })
      const code = await this.hospitalityGradeList[0]?.dictVal
      if (this.options.id) {
        this.loading = true
        this.$api.register
          .selectDetailById(this.options.id)
          .then(res => {
            if (res.data) {
              this.form = res.data
              this.loading = false
              this.instInvolved = {
                instInvolvedUserName: res.data.instInvolvedUserName
                  ? res.data.instInvolvedUserName
                  : [],
                status: res.data.status,
              }
              if (res.data.detailList) {
                this.imgList = []
                res.data.detailList.forEach(item => {
                  item.expenseFileList.forEach(v => {
                    if (!this.isShowType(v)) {
                      this.imgList.push(v)
                    }
                  })
                })
              }
            }
          })
          .catch(err => {
            console.log(err)
          })
      } else {
        this.form = {
          applyName: null,
          createName: this.userInfo.userName,
          hospitalityGrade: code,
          numberPeople: null,
          registrationType: 'KITCHEN',
          taskDate: new Date().getTime(),
          occurDate: null,
          detailList: [
            {
              cost: 0,
              contentDetail: null,
              expenseFileList: [],
            },
          ],
        }
      }
      this.$nextTick(() => {
        this.$refs.form?.clearValidate()
        this.$forceUpdate()
      })
    },
    //获取用户可以申请报销得项目名单
    getProjectNameList() {
      this.$api.project
        .projectList()
        .then(res => {
          this.projectNameList = res.data
        })
        .catch(err => {
          console.log(err)
        })
    },
    //获取用户可以申请报销得商务名单
    getbusinessNameList() {
      this.$api.businessExpense
        .getBusinessNameList()
        .then(res => {
          this.businessNameList = res.data
        })
        .catch(err => {
          console.log(err)
        })
    },
    //部门
    getDeptList() {
      // Promise.all([
      //   this.$api.sysDept.listDept(),
      //   this.$api.dict.listSysDictData('PUBLIC_EXPENSES_DEPT', true),
      // ])
      //   .then(([deptData, dictData]) => {
      //     let arr = []
      //     let publicExpensesDept = []
      //     arr = deptData.data
      //     publicExpensesDept = dictData.data
      //     const list = JSON.parse(localStorage.getItem('userInfo')).deptIdList
      //     list.forEach(v => {
      //       this.deptList.push(...arr.filter(k => v == k.id))
      //     })
      //     publicExpensesDept.forEach(v => {
      //       this.deptList.push({
      //         deptName: v.dictName,
      //         id: v.dictVal,
      //       })
      //     })
      //   })
      //   .catch(err => {})
      this.$api.sysDept
        .queryStaffDept()
        .then(res => {
          this.deptList = res.data
        })
        .catch(err => {
          console.log(err)
        })
    },
    // 删除当前行
    handleRemove(index, row) {
      if (row.id) {
        this.form.detailList[index].deleteFlag = 1
        this.detailDeleteList.push(row.id)
      }
      this.form.detailList.splice(index, 1)
      row.expenseFileList.forEach(n => {
        if (n.id) {
          this.fileDeleteList.push(n.id)
        }
        this.imgList.forEach((v, i) => {
          if ((v.temporaryId && n.temporaryId && v.temporaryId == n.temporaryId) || v.id == n.id) {
            this.imgList.splice(i, 1)
          }
        })
      })
    },
    // 提交审核
    addBusinessCost(status) {
      //处理验证逻辑，status为1则是提交
      this.status = status
      //处理提交给后端的格式
      let cond = {
        ...this.form,
        detailDeleteList: this.detailDeleteList,
        fileDeleteList: this.fileDeleteList,
      }
      if (this.status == 1) {
        let ruleTips = false
        this.form.detailList.forEach(m => {
          if (!m.contentDetail) {
            ruleTips = true
            this.$forceUpdate()
          }
        })
        if (ruleTips) {
          this.$forceUpdate()
          return
        }
        this.$refs.form.validate((valid, obj) => {
          if (valid) {
            this.loading = true
            // 判断当前单据1状态是否为0且包含流程id，是的话说明该单据是撤回的，走重新提交接口
            if (
              (this.form.status == 0 && this.form.processInstId) ||
              (this.form.status == 5 && this.form.processInstId)
            ) {
              this.$api.register
                .resubmitByReject(cond)
                .then(res => {
                  this.loading = false
                  this.showEditDialog = false
                  this.$message.success('操作成功！')
                  this.re()
                })
                .catch(err => {
                  this.loading = false
                  console.log('错误：', err)
                })
            } else {
              this.$api.register
                .startProcess(cond)
                .then(res => {
                  this.loading = false
                  this.showEditDialog = false
                  this.$message.success('操作成功！')
                  this.re()
                })
                .catch(err => {
                  this.loading = false
                  console.log('错误：', err)
                })
            }
          }
        })
      } else {
        //todo 暂存
        this.loading = true
        //* 如果有id且当前状态是暂存，则走暂存编辑接口
        if (this.form.id) {
          //todo 被删除的明细如何提交
          this.$api.register
            .undeterminedEdit(cond)
            .then(res => {
              this.loading = false
              this.showEditDialog = false
              this.$message.success('操作成功！')
              this.re()
            })
            .catch(err => {
              this.loading = false
              console.log('错误：', err)
            })
        } else {
          this.$api.register
            .tempAdd(cond)
            .then(res => {
              this.loading = false
              this.showEditDialog = false
              this.$message.success('操作成功！')
              this.re()
            })
            .catch(err => {
              this.loading = false
              console.log('错误：', err)
            })
        }
      }
    },
    selectDataChange(row) {
      this.form.applyName = row?.userName
      this.form.applyBy = row?.id
    },
    uploadIndex(i, k) {
      this.Index = i //记录当前点击哪个项目
    },
    upload(r) {
      this.temporaryList = []
      r.forEach(v => {
        if (v) {
          v.type = this.type
          v.temporaryId = Date.now()
        }
      })
      this.form.detailList.forEach((v, i) => {
        if (this.Index == i) {
          if (!v.expenseFileList) {
            v.expenseFileList = []
          }
          v.expenseFileList.push(...r)
        }
      })
      this.temporaryList.push(...r)
    },
    del(index, p_index, n) {
      this.form.detailList.forEach((v, key) => {
        if (key == p_index) {
          v.expenseFileList.forEach((k, i) => {
            if (i == index) {
              if (k.id) {
                this.fileDeleteList.push(k.id)
              }
              v.expenseFileList.splice(i, 1)
            }
          })
        }
      })
      this.imgList.forEach((item, index) => {
        if (
          (item.temporaryId && n.temporaryId && item.temporaryId == n.temporaryId) ||
          item.id == n.id
        ) {
          this.imgList.splice(index, 1)
        }
      })
    },
    invoiceUpload(r) {
      this.upload(r)
    },
    addRedStar(h, { column }) {
      return [h('span', { style: 'color: red' }, '*'), h('span', ' ' + column.label)]
    },
    //撤回
    reject() {
      this.loading = true
      this.$api.register
        .reject(this.form)
        .then(res => {
          this.loading = false
          this.showEditDialog = false
          this.$message.success('操作成功！')
          this.re()
        })
        .catch(err => {
          this.loading = false
          console.log('错误：', err)
        })
    },
    notarize() {
      if (this.form.expenseType == 'BUSINESS') {
        if (
          !this.form.disbursementType ||
          (!this.form.expenseBelongId &&
            this.form.disbursementType !== 'UNDEFINED_RATIFICATION_IMPLEMENT') ||
          (!this.form.businessContent &&
            this.form.disbursementType == 'UNDEFINED_RATIFICATION_IMPLEMENT')
        ) {
          this.$forceUpdate()
          return
        }
      }
      let cond = {
        companyType: this.form.companyType,
        expenditureExplain: this.form.expenditureExplain,
        expenseBelongId: this.form.expenseBelongId,
        businessContent: this.form.businessContent,
        expenseType: this.form.expenseType,
        disbursementType: this.form.disbursementType,
        detailList: [],
        fileDeleteList: this.fileDeleteList,
        id: this.form.id,
      }
      this.form.detailList.forEach(v => {
        cond.detailList.push({
          id: v.id,
          expenseFileList: v.expenseFileList,
        })
      })
      this.$refs.form.validate((valid, obj) => {
        if (valid) {
          this.loading = true
          this.$api.register
            .confirm(cond)
            .then(res => {
              this.loading = false
              this.showEditDialog = false
              this.$message.success('操作成功！')
              this.re()
            })
            .catch(err => {
              this.loading = false
              console.log('错误：', err)
            })
        }
      })
    },

    onDisbursementTypeChange(e) {
      if (e == 'UNDEFINED_RATIFICATION_IMPLEMENT') {
        this.form.expenseBelongId = null
        this.form.businessContent = '前期面上沟通'
        this.$forceUpdate()
        this.$nextTick(() => {
          this.$refs.businessContentRef.focus()
        })
      } else {
        this.form.businessContent = null
      }
    },
  },
}
</script>

<style scoped lang="scss">
@import '@/styles/config.scss';
.iconCost {
  padding-left: 10px;
  font-size: 28px;
  vertical-align: middle;
  cursor: pointer;
}
.color_r {
  color: #f00;
}
/deep/ .el-table .cell {
  white-space: pre-line;
  width: 100% !important;
}

/deep/ .cell .el-tooltip {
  width: 100% !important;
}
.el-form-item {
  margin-bottom: 0px !important;
}

/deep/.businessOutSource {
  //费用类型背景色
  background-color: #b2f3fe;
  border: 1px solid #ddd !important;
}
/deep/.registrationLabelClassName {
  width: 14.2%;
}
/deep/.registrationContentClassName {
  height: 75px !important;
}
.iconCostDelete {
  font-size: 28px;
  vertical-align: middle;
  cursor: pointer;
}
// 附件编码
.invoice-file {
  display: flex;
  margin-top: 5px;
  .invoice-file-left {
    width: 85%;
  }
}
/deep/.headerInvoiceClassName {
  background-color: #b2f3fe !important;
}
</style>
